<script>
import { Bar } from 'vue-chartjs'
import _ from 'lodash'

export default {
  extends: Bar,
  props: {
    data: {
      type: Object,
    },
    options: {
      type: [Object, Array],
      default: () => ({
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                precision: 0,
              },
            },
          ],
        },
      }),
    },
  },
  data() {
    return {}
  },
  mounted() {
    this.genChart()
  },
  watch: {
    data: {
      handler() {
        this.genChart()
      },
      deep: false,
    },
    options: {
      handler() {
        this.genChart()
      },
      deep: false,
    },
  },
  methods: {
    genChart() {
      if (this.data && this.data.datasets) {
        this.data.datasets = this.data.datasets.map((e) => {
          let gradient = this.$refs.canvas
            .getContext('2d')
            .createLinearGradient(0, 0, 0, 450)

          gradient.addColorStop(0, `${e.borderColor}FF`)
          gradient.addColorStop(0.4, `${e.borderColor}BB`)
          gradient.addColorStop(1, `${e.borderColor}00`)

          e.backgroundColor = gradient
          return e
        })

        this.renderChart(_.cloneDeep(this.data), this.options)
      }
    },
  },
}
</script>
