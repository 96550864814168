<template>
  <v-container fluid>
    <div class="chart-line">
      <v-row justify="end">
        <v-col cols="3">
          <v-datetime-picker label="Start" v-model="start" @input="_dateChange">
            <template v-slot:dateIcon>
              <v-icon>mdi-calendar-range</v-icon>
            </template>
            <template v-slot:timeIcon>
              <v-icon>mdi-clock-outline</v-icon>
            </template>
          </v-datetime-picker>
        </v-col>
        <v-col cols="3">
          <v-datetime-picker label="End" v-model="end" @input="_dateChange">
            <template v-slot:dateIcon>
              <v-icon>mdi-calendar-range</v-icon>
            </template>
            <template v-slot:timeIcon>
              <v-icon>mdi-clock-outline</v-icon>
            </template>
          </v-datetime-picker>
        </v-col>
        <v-col cols="3">
          <v-combobox
            v-model="serie"
            :items="series"
            label="Field"
            @change="_serieChange"
          >
          </v-combobox>
        </v-col>
      </v-row>
      <BarChart :data="timeseries" />
    </div>
    <div id="surveyAnalytics"></div>
  </v-container>
</template>

<script>
import * as SurveyVue from 'survey-core'
import * as SurveyAnalytics from 'survey-analytics'
import moment from 'moment'

import BarChart from '@/components/BarChart'

import Form from '@/store/models/Form'
import SurveyAPI from '@/store/models/Survey'

import 'survey-analytics/survey.analytics.css'

const dateFormat = 'yyyy-MM-DD HH:mm'

export default {
  components: {
    BarChart,
  },

  data() {
    return {
      survey: null,
      data: [],
      id: this.$route.params.id,
      approve: [],
      timeseries: null,
      start: null,
      end: null,
      trunc: 'day',
      serie: 'day',
    }
  },

  computed: {
    series() {
      let series = ['year', 'month', 'day', 'hour', 'minute', 'second']

      if (this.trunc == 'month') {
        series.splice(0, 1)
      } else if (this.trunc == 'day') {
        series.splice(0, 2)
      } else if (this.trunc == 'hour') {
        series.splice(0, 3)
      } else if (this.trunc == 'minute') {
        series.splice(0, 4)
      } else if (this.trunc == 'second') {
        series = []
      }

      return series
    },
  },

  mounted() {
    // const surveyAnalyticsData = new SurveyAnalytics.DataTables(
    //   document.getElementById("surveyAnalytics"),
    //   this.survey,
    //   this.data
    // );
    this.defaultTimeRange()
    this.generateSurveyAnalytics()
  },

  methods: {
    generateSurveyAnalytics() {
      this.genSurvey().then((res) => {
        const data = res.form && res.form.json

        this.getTimeSeries(data)

        const model = new SurveyVue.Model(
          data || { pages: [{ name: 'page1' }] }
        )
        const surveyAllQuestions = model.getAllQuestions()
        surveyAllQuestions.reduce((o, e) => {
          // if(e.hasInput && e.inputType) {
          if (e.hasInput && !e.noFileChosenCaption) {
            o.push(e)
          }
          return o
        }, [])

        const surveyResults = res.surveys

        const vizPanelOptions = {
          haveCommercialLicense: true,
          labelTruncateLength: 27,
          allowDynamicLayout: false,
        }

        const surveyAnalyticsData = new SurveyAnalytics.VisualizationPanel(
          surveyAllQuestions,
          surveyResults,
          vizPanelOptions
        )

        document.getElementById('surveyAnalytics').innerHTML = ''

        surveyAnalyticsData.render(document.getElementById('surveyAnalytics'))
      })
    },
    defaultTimeRange() {
      this.start = moment()
        .startOf('month')
        .format(dateFormat)
      this.end = moment()
        .endOf('month')
        .format(dateFormat)
    },
    getApprove(data = []) {
      return data.reduce((o, e) => {
        if (e.approve) {
          o.push(e.name)
        }
        if (e.elements) {
          o = [...o, ...this.getApprove(e.elements)]
        }

        return o
      }, [])
    },
    getTimeSeries(data) {
      this.approve =
        data &&
        data.pages.reduce((o, e) => {
          const items = this.getApprove(e.elements)
          o = [...o, ...items]
          return o
        }, [])

      const options = {
        params: {
          page: 1,
          perpage: 0,
          condition: JSON.stringify({
            formId: this.id,
          }),
          approve: this.approve && this.approve.join(','),
          trunc: this.serie,
          startdate: this.start && moment(this.start).toDate(),
          enddate: this.end && moment(this.end).toDate(),
        },
      }
      SurveyAPI.report(options).then((res) => {
        this.timeseries = res
        return res
      })
      return []
    },
    async genSurvey() {
      const [form, surveys] = await Promise.all([
        this.getForm(),
        this.getsSurvey(),
      ])

      return {
        form,
        surveys,
      }
    },
    getForm() {
      return Form.item(this.id).then((res) => res)
    },
    getsSurvey() {
      const options = {
        params: {
          condition: {},
          perpage: 0,
          sort: { _id: 1 },
          startdate: this.start && moment(this.start).toDate(),
          enddate: this.end && moment(this.end).toDate(),
        },
      }

      return SurveyAPI.answer(this.id, options).then((res) =>
        res.data.map((e) => e.answer)
      )
    },
    _dateChange() {
      // default
      if (!(this.start && this.end)) {
        this.defaultTimeRange()
      }

      let trunc = 'day'

      // diff
      const start = moment(this.start, dateFormat)
      const end = moment(this.end, dateFormat)
      let diff = end.diff(start, 'seconds')

      const minute = 60
      const hour = minute * 60
      const day = hour * 24
      const year = day * 364

      if (year * 2 < diff) {
        trunc = 'year'
      } else if (end.diff(start, 'months') > 1) {
        trunc = 'month'
      } else if (day * 2 < diff) {
        trunc = 'day'
      } else if (hour * 2 < diff) {
        trunc = 'hour'
      } else if (minute > 2 < diff) {
        trunc = 'minute'
      } else {
        trunc = 'second'
      }
      this.trunc = trunc
      this.serie = trunc

      this._serieChange()
    },
    _serieChange() {
      // this.getTimeSeries()
      this.generateSurveyAnalytics()
    },
  },
}
</script>

<style>
.chart-line {
  padding: 1vh 1vw;
}
</style>
